import { useState, useEffect } from "react";
import dayjs from "dayjs";
import FulfillmentPortalService from "../services/FulfillmentPortalService";

const superSearchService = new FulfillmentPortalService();

const ViewOrder_fetch = (oylWoocommerceId: string) => {
  const [orderData, setOrderData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const order = await superSearchService.getOrderByWooCommerceId(oylWoocommerceId);
        
        if (!order) {
          throw new Error("Order not found");
        }

        // Ensure woocommerce order ID is included
        order.orderId = order.order_num || oylWoocommerceId;

        const timelineOrderResp = await fetch(`/portal/timeline-for-order/${order.oyl_id}`);
        const timelineOrder = await timelineOrderResp.json();
        order.timelineOrder = timelineOrder;

        const timelineCustomerResp = await fetch(`/portal/timeline-for-customer/${order.oyl_id_customer}`);
        const timelineCustomer = await timelineCustomerResp.json();
        order.timelineCustomer = timelineCustomer;

        setOrderData(order);

      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    if (oylWoocommerceId) {
      fetchOrderDetails();
    } else {
      setLoading(false);
    }
  }, [oylWoocommerceId]);

  return { orderData, loading, error };
};

export default ViewOrder_fetch;