import React from "react";
import ReactDOM from "react-dom/client";
import App2 from "./portal/App2";
import { ClerkProvider } from "@clerk/clerk-react";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { lg } from "./shared/utils/oyl_log";
import Env from "./shared/Env";

const clerkPublishableKey = Env.getClerkRAPKey();
lg("Clerk Key available:", !!clerkPublishableKey);

if (!clerkPublishableKey) {
  throw new Error("Missing Clerk publishable key. Check your .env file.");
}

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean, error: any }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error("React error boundary caught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '20px' }}>
          <h1>Something went wrong.</h1>
          <pre>{this.state.error?.toString()}</pre>
        </div>
      );
    }

    return this.props.children;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ClerkProvider publishableKey={clerkPublishableKey}>
        <ErrorBoundary>
          <App2 />
        </ErrorBoundary>
      </ClerkProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals();