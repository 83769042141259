// FormSection.tsx
import React from "react";
import { TextField, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

export interface FormField {
  label: string;
  name: string;
  type?: "text" | "date";
}

interface FormSectionProps {
  title: string;
  fields: FormField[];
  formData: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDateChange?: (field: string, newValue: Dayjs | null) => void;
  loading: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void; // Add onKeyDown prop
}

const FormSection: React.FC<FormSectionProps> = ({
  title,
  fields,
  formData,
  handleChange,
  handleDateChange,
  loading,
  onKeyDown, // Accept onKeyDown prop
}) => {
  return (
    <Grid container spacing={2}>
      {/* Title - Full width */}
      <Box
        sx={{
          flexGrow: 1,
          alignItems: "center",
          backgroundColor: "#B5F4FE",
          padding: "2px",
          position: "relative",
          width: "100%",
        }}
      >
        <Grid>
          <Typography variant="h6" gutterBottom sx={{ marginBottom: "0px" }}>
            {title}
          </Typography>
        </Grid>
      </Box>

      {/* Fields */}
      {fields.map((field) => (
        <Grid key={field.name}>
          {field.type === "date" ? (
            <DatePicker
              label={field.label}
              value={ formData[field.name] ? dayjs(formData[field.name], "YYYY-MM-DD", true) : null }
              onChange={(newValue) => {
                if (handleDateChange) {
                  handleDateChange(field.name, newValue);
                }
              }}
              slotProps={{
                textField: {
                  disabled: loading,
                  fullWidth: true,
                },
              }}
            />
          ) : (
            <TextField
              label={field.label}
              name={field.name}
              value={formData[field.name] || ""}
              onChange={handleChange}
              onKeyDown={onKeyDown} // Pass onKeyDown to the input
              disabled={loading}
              fullWidth
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default FormSection;
