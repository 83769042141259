import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@mui/material';

interface ConfirmationModalWithInputProps {
    message: string;
    onCancel: () => void;
    onConfirm: (inputValue: string) => void;
}

const ConfirmationModalWithInput: React.FC<ConfirmationModalWithInputProps> = ({ message, onCancel, onConfirm }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleConfirm = () => {
    onConfirm(inputValue.trim());
  };

  const isInputValid = inputValue.trim().length > 0;

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>
      <DialogContentText sx={{ whiteSpace: 'pre-line' }}>
        {message}
      </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          variant="outlined"
          value={inputValue}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" autoFocus>
          Cancel
        </Button>
        <Button 
          onClick={handleConfirm} 
          color="error"
          disabled={!isInputValid}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModalWithInput;