// Env.js
// These are convenience functions to access values from .env

class EnvService {
  constructor() {
    /** @type {Environment} */
    this.currentEnv = this.getCurrentEnv();
    this.validateRequiredEnvVars();

    // Cashing these as properties for easy/quick reference:
    this.tp = this.getTablePrefix();
    this.db = this.getDbConfig();
    this.sess = this.getSessionConfig();
  }

  validateRequiredEnvVars() {
    if (typeof window !== 'undefined') {
      // Browser environment - check React vars
      const required = ['REACT_APP_CLERK_PUBLISHABLE_KEY'];
      const missing = required.filter(key => !process.env[key]);
      if (missing.length) {
        throw new Error(`Missing required React environment variables: ${missing.join(', ')}`);
      }
    } else {
      // Node environment - check server vars
      const required = ['WHICH_ENV', 'DB_HOST', 'DB_USER', 'DB_NAME'];
      const missing = required.filter(key => !process.env[key]);
      if (missing.length) {
        throw new Error(`Missing required environment variables: ${missing.join(', ')}`);
      }
    }
  }

  getPort() {
    return process.env.PORT || 3000;
  }

  getCurrentEnv() {
    return process.env.WHICH_ENV || 'old';
  }

  /**
   * Gets the appropriate database table prefix for the current environment
   * @returns {string}
   */
  getTablePrefix() {
    const prefixMap = {
      dev: process.env.DB_TABLE_PREFIX_DEV,
      prod: process.env.DB_TABLE_PREFIX_PROD,
      old: process.env.DB_TABLE_PREFIX_OLD
    };
    return prefixMap[this.currentEnv] || process.env.DB_TABLE_PREFIX_OLD;
  }

  /**
   * Gets database configuration
   * @returns {{ host: string, user: string, name: string, password: string }}
   */
  getDbConfig() {
    return {
      host: process.env.DB_HOST,
      port: process.env.DB_PORT,
      user: process.env.DB_USER,
      database: process.env.DB_NAME,
      password: process.env.AWS_MYSQL_PASSWORD
    };
  }

  /**
   * Gets API configuration
   * @returns {{ key: string, token: string }}
   */
  getApiConfig() {
    return {
      key: process.env.EHS_API_KEY,
      token: process.env.EHS_API_TOKEN
    };
  }

  getEHSAPIKey() {
    const keyMap = {
      dev: process.env.EHS_API_KEY_DEV,
      prod: process.env.EHS_API_KEY_PROD,
      old: process.env.EHS_API_KEY,
      faucet: process.env.EHS_API_KEY
    };
    return keyMap[this.currentEnv] || process.env.EHS_API_KEY;
  }

  getEHSAPIToken() {
    const keyMap = {
      dev: process.env.EHS_API_TOKEN_DEV,
      prod: process.env.EHS_API_TOKEN_PROD,
      old: process.env.EHS_API_TOKEN,
      faucet: process.env.EHS_API_TOKEN
    };
    return keyMap[this.currentEnv] || process.env.EHS_API_TOKEN;
  }

  /**
   * Gets Clerk configuration for the current environment
   * @returns {{
   *   publishableKey: string,
   *   secretKey: string,
   *   signInUrl: string,
   *   signUpUrl: string,
   *   afterSignInUrl: string,
   *   afterSignUpUrl: string,
   *   signInFallbackUrl: string,
   *   signUpFallbackUrl: string
   * }}
   */
  getClerkConfig() {
    // For browser environments, use REACT_APP_ prefixed keys
    const publishableKey = typeof window !== 'undefined'
      ? process.env.REACT_APP_CLERK_PUBLISHABLE_KEY
      : process.env.CLERK_PUBLISHABLE_KEY;

    const secretKey = typeof window !== 'undefined'
      ? process.env.REACT_APP_CLERK_SECRET_KEY
      : process.env.CLERK_SECRET_KEY;

    return {
      publishableKey,
      secretKey,
      signInUrl: process.env.CLERK_SIGN_IN_URL,
      signUpUrl: process.env.CLERK_SIGN_UP_URL,
      afterSignInUrl: process.env.CLERK_AFTER_SIGN_IN_URL,
      afterSignUpUrl: process.env.CLERK_AFTER_SIGN_UP_URL,
      signInFallbackUrl: process.env.CLERK_SIGN_IN_FALLBACK_REDIRECT_URL,
      signUpFallbackUrl: process.env.CLERK_SIGN_UP_FALLBACK_REDIRECT_URL
    };
  }

  getClerkRAPKey() {
    return process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
  }
  getClerkRASKey() {
    return process.env.REACT_APP_CLERK_SECRET_KEY;
  }
  getClerkPKey() {
    return process.env.CLERK_PUBLISHABLE_KEY;
  }
  getClerkSKey() {
    return process.env.CLERK_SECRET_KEY;
  }
  getClerkAPIURL() {
    return process.env.CLERK_API_URL || 'https://api.clerk.dev';
  }

  clerkKeysMissing() {
    return (!this.getClerkPKey() || !this.getClerkSKey());
  }

  /**
   * Gets session configuration
   * @returns {{ simpleKey: string, secretKey: string }}
   */
  getSessionConfig() {
    return {
      simpleKey: process.env.OYL_FULFILLMENT_PORTAL_SIMPLE_SESSION_KEY,
      secretKey: process.env.OYL_FULFILLMENT_PORTAL_SECRET_SESSION_KEY
    };
  }

  /**
   * Checks if the current environment is production
   * @returns {boolean}
   */
  isProd() {
    return this.currentEnv === process.env.ENV_PROD;
  }

  /**
   * Checks if the current environment is development
   * @returns {boolean}
   */
  isDev() {
    return this.currentEnv === process.env.ENV_DEV;
  }

  /**
   * Checks if the current environment is the old environment
   * @returns {boolean}
   */
  isOld() {
    return this.currentEnv === process.env.ENV_OLD;
  }

  /**
   * Checks if the current environment is the 1.5 faucet
   * @returns {boolean}
   */
  isFaucet() {
    return this.currentEnv === process.env.ENV_1PT5;
  }
}

// Create and export a singleton instance
const Env = new EnvService();
Object.freeze(Env);

export default Env;