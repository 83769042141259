// ViewSearch_fetch.tsx

import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import FulfillmentPortalService, { AllowedSuperSearchKeys } from "../services/FulfillmentPortalService";

const superSearchService = new FulfillmentPortalService();

// Define what the search fields can contain
type SearchFieldValue = string | number | Dayjs | null;
type SearchFields = {
  [K in keyof AllowedSuperSearchKeys]?: SearchFieldValue;
};

const ViewSearch_fetch = () => {
  const [searchFields, setSearchFields] = useState<SearchFields>({});
  const [results, setResults] = useState<any[]>([]); // TODO: Use proper type from service
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleSearch = async () => {
    setLoading(true);
    
    // Convert search fields to the format expected by the service
    const formattedSearchFields = Object.entries(searchFields).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined) {
        if (dayjs.isDayjs(value)) {
          acc[key as keyof AllowedSuperSearchKeys] = value.format("YYYY-MM-DD");
        } else if (typeof value === 'number') {
          acc[key as keyof AllowedSuperSearchKeys] = value.toString();
        } else if (typeof value === 'string') {
          acc[key as keyof AllowedSuperSearchKeys] = value;
        }
      }
      return acc;
    }, {} as Partial<AllowedSuperSearchKeys>);

    try {
      const data = await superSearchService.executeSuperSearch(formattedSearchFields);
      setResults(data);
    } catch (err) {
      setError("Error fetching data");
      console.error("Error fetching search results:", err);
    } finally {
      setLoading(false);
    }
  };

  return {
    searchFields,
    setSearchFields,
    results,
    loading,
    error,
    handleSearch,
  };
};

export default ViewSearch_fetch;