import React from "react";
import { Typography, Box } from "@mui/material";

interface ResultsHeaderProps {
  title: string;
}

const ResultsHeader: React.FC<ResultsHeaderProps> = ({ title }) => {
  return (
    <Box sx={{ backgroundColor: "#f0f0f0", padding: "8px 16px" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        {title}
      </Typography>
    </Box>
  );
};

export default ResultsHeader;
