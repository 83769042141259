import React from "react";
import { Box } from "@mui/material";
import ResultsHeader from "./ResultsHeader";
import ResultsRow from "./ResultsRow";

interface Analyte {
  order_lab_name: string;
  result_lab_name: string;
  lab_reference_range: string | null;
  lab_abnormal_flag: string | null;
  value: string;
  value_unit: string | null;
}

interface ResultsTableProps {
  analytes: Analyte[];
}

const ResultsTable: React.FC<ResultsTableProps> = ({ analytes }) => {

  const groupedAnalytes = analytes.reduce(
    (acc: Record<string, Analyte[]>, analyte) => {
      const key = analyte.order_lab_name;
      if (!acc[key]) acc[key] = [];
      acc[key].push(analyte);
      return acc;
    },
    {}
  );

  return (
    <Box sx={{ border: "1px solid #ddd", borderRadius: "8px", overflow: "hidden" }}>
      {Object.entries(groupedAnalytes).map(([orderLabName, group]) => (
        <React.Fragment key={orderLabName}>
          {/* Header for Each Group */}
          <ResultsHeader title={orderLabName} />
          {/* Rows */}
          {group.map((analyte, idx) => (
            <ResultsRow
              key={idx}
              resultLabName={analyte.result_lab_name}
              value={analyte.value}
              unit={analyte.value_unit}
              referenceRange={analyte.lab_reference_range}
              abnormalFlag={analyte.lab_abnormal_flag}
            />
          ))}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default ResultsTable;
