// ViewOrder.tsx

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Dayjs } from "dayjs";
import { ThemeProvider, Button, Box, CircularProgress, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomerFormSection from "./CustomerFormSection";
import BillingFormSection from "./BillingFormSection";
import OrderSection from "./OrderSection";
import theme from "../style/theme";
import ViewOrder_fetch from "./ViewOrder_fetch";
import Grid from "@mui/material/Grid2";
import { getFlatJSONDiff } from "../../shared/utils/dataTools";
import { getTimelineDisplayData } from "./ViewOrder_digest";
import ConfirmationModal from "../nav_bar/ConfirmationModalComponent";
import FulfillmentPortalService from "../services/FulfillmentPortalService";
import { useUser } from "@clerk/clerk-react";
import { lg } from "../../shared/utils/oyl_log";
import ConfirmationModalWithInput from "../nav_bar/ConfirmationModalWithInput";

const fulfillmentPortalService = new FulfillmentPortalService();

const ViewOrder = () => {
  const { user, isSignedIn, isLoaded } = useUser();
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get("orderId") || "";
  const { orderData, loading, error } = ViewOrder_fetch(orderId);
  const [formData, setFormData] = useState<any>({});
  const [chipData, setChipData] = useState<string[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [initialData, setInitialData] = useState<any>({});
  const [timelineOrder, setTimelineOrder] = useState<any[]>([]);
  const [timelineCustomer, setTimelineCustomer] = useState<any[]>([]);
  const [editNote, setEditNote] = useState<string>("");
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [showMoveOrderModal, setShowMoveOrderModal] = useState(false);
  const [showTryMoveOrderAgainModal, setShowTryMoveOrderAgainModal] = useState(false);
  const [lastMoveIdInput, setLastMoveIdInput] = useState<string>("");
  const [awaitingService, setAwaitingService] = useState(false);

  const userId = (user && user.publicMetadata && user.publicMetadata.admin_id) ?  user.publicMetadata.admin_id as string : null;
  const userIsAdmin: boolean = Boolean(
    user &&
    user.publicMetadata &&
    typeof user.publicMetadata.role === "string" &&
    user.publicMetadata.role === "admin"
  );

  // Update formData and chipData when orderData changes
  useEffect(() => {
    lg(' -- orderData: ', orderData);
    if (orderData) {
      const mappedFormData = {
        orderId: orderData.orderId || orderData.order_num || "",
        customerId: orderData.oyl_id_customer,
        customerFirstName: orderData.customer_first_name || "",
        customerLastName: orderData.customer_last_name || "",
        customerMiddleName: orderData.customer_middle_name || "",
        customerBirthDate: orderData.customer_birth_date || "",
        customerEmail: orderData.customer_email || "",
        customerPhone: orderData.customer_phone || "",
        customerAddress1: orderData.customer_address_line || "",
        customerAddress2: orderData.customer_address_line2 || "",
        customerCity: orderData.customer_address_city || "",
        customerState: orderData.customer_address_state || "",
        customerZip: orderData.customer_address_zip || "",
        billingId: orderData.oyl_id_billing,
        billingFirstName: orderData.billing_first_name || "",
        billingLastName: orderData.billing_last_name || "",
        billingMiddleName: orderData.billing_middle_name || "",
        billingEmail: orderData.billing_email || "",
        billingPhone: orderData.billing_phone || "",
        billingAddress1: orderData.billing_address_line || "",
        billingAddress2: orderData.billing_address_line2 || "",
        billingCity: orderData.billing_address_city || "",
        billingState: orderData.billing_address_state || "",
        billingZip: orderData.billing_address_zip || "",
        oyl_skus_named: orderData.oyl_skus_named || [],
        orderNote: orderData.oyl_order_note || "",
        orderHowDidYou: orderData.oyl_how_did_you || "",
        orderCoupons: orderData.oyl_coupons || [],
        oyl_id_order: orderData.oyl_id,
        oyl_status: orderData.oyl_status
      };

      setTimelineOrder(orderData.timelineOrder);
      setTimelineCustomer(orderData.timelineCustomer);

      setFormData(mappedFormData);
      setChipData(mappedFormData.oyl_skus_named);
      setInitialData(mappedFormData);

      //lg(' -- orderData.ehs_id: ', orderData.ehs_id);
    }
  }, [orderData]);
  
  // Add a new state to track if Enter was pressed
  const [enterPressed, setEnterPressed] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
    setIsButtonDisabled(false);
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEditNote(value);
  };

  const handleDateChange = (field: string, newValue: Dayjs | null) => {
    const formattedDate = newValue ? newValue.format("YYYY-MM-DD") : null;
    setFormData((prev: any) => ({ ...prev, [field]: formattedDate }));
    setIsButtonDisabled(false);
  };

  const handleAddChip = (chip: string) => {
    if (!chipData.includes(chip)) {
      const updatedChips = [...chipData, chip];
      setChipData(updatedChips);
      setFormData((prev: any) => ({
        ...prev,
        oyl_skus_named: updatedChips,
      }));
      setIsButtonDisabled(false);
    }
  };

  const handleDeleteChip = (chipToDelete: string) => {
    const updatedChips = chipData.filter((chip) => chip !== chipToDelete);
    setChipData(updatedChips);
    setFormData((prev: any) => ({
      ...prev,
      oyl_skus_named: updatedChips,
    }));
    setIsButtonDisabled(false);
  };

  const hasBilling = ():boolean => {
    const retval = orderData !== null &&
      orderData.billing_first_name !== null &&
      orderData.billing_address_line !== null;
    //lg('hasBilling? ', retval);
    return retval;
  }

  // Return an array of objects that can be used to build a timeline display
  const timelineOrderDisplayData = () => {
    return getTimelineDisplayData(timelineOrder);
  }
  const timelineCustomerDisplayData = () => {
    return getTimelineDisplayData(timelineCustomer);
  }

  function updateButtonState() {
    let isEditNoteChanged = editNote !== "";
    if (isEditNoteChanged) {
      setIsButtonDisabled(false);
    } else if (!initialData) {
      setIsButtonDisabled(true);
    } else {
      const isDataChanged =
        JSON.stringify(formData) !== JSON.stringify(initialData);
      setIsButtonDisabled(!isDataChanged);
    }
  }

  // Check for changes to enable or disable the commit button
  useEffect(() => {
    updateButtonState();
  }, [formData, initialData, editNote]);


  const handleCommitChanges = async () => {
    if (isButtonDisabled) return null;
  
    setIsButtonDisabled(true);
    try {
      const afterChangesOrNull = getFlatJSONDiff(initialData, formData);
      if (!afterChangesOrNull) {
        if (editNote !== "") {
          // Handle naked edit note
          const resp = await fetch('/api/note-in-timeline', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              user_id: userId,
              oyl_id_order: initialData.oyl_id_order,
              note: editNote 
            })
          });
          if (!resp.ok) {
            throw new Error(`Error: ${resp.statusText}`);
          }
          window.location.reload();
        } else {
          console.error('ERROR: Tried to update DB, but no diff found in data');
          return;
        }
      }
  
      const afterChanges = afterChangesOrNull || {};
      const beforeChanges = getFlatJSONDiff(formData, initialData) || {};
  
      const metaData = {
        user_is_admin: userIsAdmin,
        user_id: userId,
        oyl_id_customer: initialData.customerId as string,
        oyl_id_billing: initialData.billingId as string,
        oyl_id_order: initialData.oyl_id_order as string,
        edit_note: editNote
      };
  
      setAwaitingService(true);
      await fulfillmentPortalService.executeSuperUpdate(
        metaData,
        beforeChanges,
        afterChanges
      );
      setAwaitingService(false);
  
      setEditNote("");
    } catch (error) {
      console.error('Error: Failed to update from super view: ', error);
    } finally {
      window.location.reload();
    }
  };

  // First send up an are-you-sure ConfirmationModal.
  // If confirmed, then the order will be cancelled.
  const handleCancelOrderClick = async () => {
    setShowCancelConfirmationModal(true); // Show the modal
  }

  const handleMoveOrderClick = async () => {
    setShowMoveOrderModal(true);
  }

  // Invoked when ConfirmationModal for canceling order has "Confirm" clicked.
  // Hits our endpoint for canceling the order.
  const followThroughWithOrderCancel = async () => {
    setShowCancelConfirmationModal(false); // Close the modal
    if (!orderData.ehs_id || orderData.ehs_id < 0) {
      console.error("ERROR: Tried to cancel an order without a valid EHS order ID: ", orderData.ehs_id);
      return;
    }

    setAwaitingService(true);
    const success = await fulfillmentPortalService.cancelOrderByOylIdOrder(
      userIsAdmin,
      userId,
      initialData.oyl_id_order,
      orderData.ehs_id,
      editNote,
      'other',
      'Own Your Labs customer service team action'
    );
    setAwaitingService(false);

    if (success) {
      window.location.reload();
    } else {
      console.log('CANCEL ORDER FAILED');
    }
  }

  function getMoveOrderConfirmationText():string {
    return "The current OYL Customer ID for this order is " + formData.customerId + ".\n" +
      "Please enter the OYL Customer ID where this order should be moved to:";
  }
  function getTryMoveAgainText():string {
    return 'You entered "' + lastMoveIdInput + '".\nThat was not recognized as a proper OYL Customer ID.' +
      'Please try again:';
  }

  const followThroughWithMoveOrder = async (userInput:string) => {
    setLastMoveIdInput(userInput);
    setAwaitingService(true);
    setShowMoveOrderModal(false);
    setShowTryMoveOrderAgainModal(false);
    const success = await fulfillmentPortalService.moveOrderToDifferentCustomer(
      userId,
      initialData.oyl_id_order,
      orderData.oyl_id_customer,
      userInput,
      editNote
    );
    setAwaitingService(false);
    if (success) {
      window.location.reload();
    } else {
      setShowTryMoveOrderAgainModal(true);
    }
  }

  // Invoked when ConfirmationModal for canceling order has "Cancel" clicked.
  // Closes the modal and does nothing else.
  const abortCancelOrder = async () => {
    setShowCancelConfirmationModal(false); // Close the modal
    lg("Order cancellation aborted");
  }

  // Add event listener for Enter key
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter" && !isButtonDisabled) {
        // Force input blur to commit any pending changes to state
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement && activeElement.tagName === "INPUT") {
          activeElement.blur(); // Trigger any pending change events
          // Set flag to true to indicate Enter was pressed
          setEnterPressed(true);
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isButtonDisabled]);

  useEffect(() => {
    if (enterPressed) {
      // Ensure state is fully synchronized
      setTimeout(() => {
        handleCommitChanges();
        setEnterPressed(false); // Reset the flag
      }, 0); // Delay ensures the latest state is applied
    }
  }, [enterPressed, formData]); // Watch for formData changes

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        {loading ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1} sx={{ padding: "10px" }}>
            {/* Order Section */}
            <OrderSection
              formData={formData}
              handleChange={handleChange}
              handleAddChip={handleAddChip}
              handleDeleteChip={handleDeleteChip}
              chipData={chipData}
              loading={loading}
            />

            {/* Customer Section */}
            <CustomerFormSection
              formData={formData}
              handleChange={handleChange}
              handleDateChange={handleDateChange}
              loading={loading}
            />

            {/* Billing Section */}
            {hasBilling() ? (
              <BillingFormSection
                formData={formData}
                handleChange={handleChange}
                loading={loading}
              />
            ) : null }

            {/* Edit Note Field */}
            <div className="mui-view-separate-full-row">
              <TextField
                label="Edit note"
                name="editNote"
                value={editNote}
                onChange={handleNoteChange}
              />
            </div>

            {/* Commit Changes Button */}
            <Grid>
              <Box sx={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCommitChanges}
                  disabled={isButtonDisabled}
                >
                  Commit Changes
                </Button>
              </Box>

              {/* Cancel Order Button */}
              { formData.oyl_status !== "order_canceled" && !awaitingService &&
                <Box sx={{ marginTop: "20px" }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCancelOrderClick}
                    disabled={false}
                  >
                    Cancel Order
                  </Button>
                  {/* Move Order to Button */}
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleMoveOrderClick}
                      disabled={false}
                      sx={{ marginLeft: "20px"}}
                    >
                      Move Order by OYL Customer ID
                  </Button>
                </Box>
              }
            </Grid>

            {/* Confirmation Modal */}
            {showCancelConfirmationModal && (
              <ConfirmationModal
                message="Are you sure you want to kill this order?"
                onCancel={abortCancelOrder}
                onConfirm={followThroughWithOrderCancel}
              />
            )}

            {/* Move Order Modal */}
            {showMoveOrderModal && (
              <ConfirmationModalWithInput
                message={getMoveOrderConfirmationText()}
                onCancel={() => setShowMoveOrderModal(false)}
                onConfirm={followThroughWithMoveOrder}
              />
            )}
            {showTryMoveOrderAgainModal && (
              <ConfirmationModalWithInput
                message={getTryMoveAgainText()}
                onCancel={() => setShowTryMoveOrderAgainModal(false)}
                onConfirm={followThroughWithMoveOrder}
              />
            )}

            { timelineOrder.length > 0 ?
              (
                <div className="mui-form-section-wrapper">
                  <Box
                    sx={{
                      flexGrow: 1,
                      alignItems: "center",
                      backgroundColor: "#B5F4FE",
                      padding: "2px",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <Grid>
                      <Typography variant="h6" gutterBottom sx={{ marginBottom: "0px" }}>Order Timeline</Typography>
                    </Grid>
                  </Box>

                  { timelineOrderDisplayData().map((item:any) => (
                    <Box
                      sx={{
                        flexGrow: 1,
                        backgroundColor: item.background,
                        padding: "2px",
                        marginTop: "10px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    >
                      <div className="mui-timeline-blob-row">
                        <div className="mui-timeline-blob-time">{item.time} : </div>
                        <div className="mui-timeline-blob-label">{item.name}</div>
                      </div>
                      { item.details.map((message:string) => (
                        <div className="mui-timeline-blob-row">
                          <div className="mui-timline-blob-data-wrapper">{message}</div>
                        </div>
                      ))}
                    </Box>
                  ))}

                </div>
              ) : null
            }

            { timelineCustomer.length > 0 ?
              (
                <div className="mui-form-section-wrapper">
                  <Box
                    sx={{
                      flexGrow: 1,
                      alignItems: "center",
                      backgroundColor: "#B5F4FE",
                      padding: "2px",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <Grid>
                      <Typography variant="h6" gutterBottom sx={{ marginBottom: "0px" }}>Customer Timeline</Typography>
                    </Grid>
                  </Box>

                  { timelineCustomerDisplayData().map((item:any) => (
                    <Box
                      sx={{
                        flexGrow: 1,
                        backgroundColor: item.background,
                        padding: "2px",
                        marginTop: "10px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    >
                      <div className="mui-timeline-blob-row">
                        <div className="mui-timeline-blob-time">{item.time} : </div>
                        <div className="mui-timeline-blob-label">{item.name}</div>
                      </div>
                      { item.details.map((message:string) => (
                        <div className="mui-timeline-blob-row">
                          <div className="mui-timline-blob-data-wrapper">{message}</div>
                        </div>
                      ))}
                    </Box>
                  ))}

                </div>
              ) : null
            }

          </Grid>
        )}
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default ViewOrder;
