import React from "react";
import { Box, Typography } from "@mui/material";

interface ResultsRowProps {
  resultLabName: string;
  value: string;
  unit: string | null;
  referenceRange: string | null;
  abnormalFlag: string | null;
}

const ResultsRow: React.FC<ResultsRowProps> = ({
  resultLabName,
  value,
  unit,
  referenceRange,
  abnormalFlag,
}) => {
  // Parse and round range values
  const parseRange = () => {
    if (!referenceRange) return { min: null, max: null };
    if (referenceRange.includes("-")) {
      const [min, max] = referenceRange.split("-").map((num) => parseFloat(num));
      return { min, max };
    }
    return { min: parseFloat(referenceRange.replace(/[^\d.]/g, "")), max: null };
  };

  const { min, max } = parseRange();
  const valueFloat = parseFloat(value);
  const isOutOfRange = abnormalFlag === "HH" || abnormalFlag === "LL";

  // Adjust dot position
  const calculatePosition = () => {
    if (min !== null && max !== null) {
      if (valueFloat < min) return -2.5; // Slightly off the left edge
      if (valueFloat > max) return 100 + 2.5; // Slightly off the right edge
      return ((valueFloat - min) / (max - min)) * 100; // Scaled position
    }
    return 0;
  };

  const dotPosition = calculatePosition();
  const dotColor = isOutOfRange ? "#ff9800" : "#4caf50"; // Orange for out-of-range, green otherwise

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "16px 0",
        borderBottom: "1px solid #ddd",
        justifyContent: "space-between",
      }}
    >
      {/* Left Container: Result Name with Value and Units */}
      <Box sx={{ width: "40%", paddingLeft: "16px", display: "flex", gap: "4px" }}>
        <Typography variant="body1" sx={{ fontWeight: "bold", color: "#000" }}>
          {resultLabName} -
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: isOutOfRange ? dotColor : "#000", // Apply color if out of range
          }}
        >
          {`${valueFloat.toFixed(1)} ${unit ?? ""}`}
        </Typography>
      </Box>

      {/* Center Container: Range Bar */}
      <Box
        sx={{
          width: "40%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        {/* Range Bar */}
        <Box
          sx={{
            position: "relative",
            height: "12px",
            width: "100%",
            backgroundColor: "#e0e0e0",
            borderRadius: "6px",
          }}
        >
          {/* Dot Marker */}
          <Box
            sx={{
              position: "absolute",
              left: `${dotPosition}%`,
              transform: "translate(-50%, -50%)",
              top: "50%",
              width: "14px",
              height: "14px",
              borderRadius: "50%",
              backgroundColor: dotColor,
              border: "2px solid white",
              zIndex: 3,
            }}
          >
            {/* Value Label */}
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                top: "-23px",
                left: "50%",
                transform: "translate(-50%, 0%)",
                backgroundColor: "white",
                padding: "2px 4px",
                borderRadius: "4px",
                fontWeight: "bold",
                border: `1px solid ${dotColor}`,
                color: "#000",
              }}
            >
              {valueFloat.toFixed(1)}
            </Typography>
          </Box>
        </Box>

        {/* Min Range Label */}
        {min !== null && (
          <Typography
            variant="caption"
            sx={{
              position: "absolute",
              top: "calc(100% + 4px)",
              left: "0",
              transform: "translateX(-5px)",
            }}
          >
            {min.toFixed(1)}
          </Typography>
        )}

        {/* Max Range Label */}
        {max !== null && (
          <Typography
            variant="caption"
            sx={{
              position: "absolute",
              top: "calc(100% + 4px)",
              right: "0",
              transform: "translateX(5px)",
            }}
          >
            {max.toFixed(1)}
          </Typography>
        )}

        {/* Single Unit Label: Centered */}
        {unit && (
          <Typography
            variant="caption"
            sx={{
              position: "absolute",
              top: "calc(100% + 4px)",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {unit}
          </Typography>
        )}
      </Box>

      {/* Right Container: Empty */}
      <Box sx={{ width: "20%" }} />
    </Box>
  );
};

export default ResultsRow;
