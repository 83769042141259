// CustomerFormSection.tsx

import React from "react";
import { Dayjs } from "dayjs";
import FormSection, { FormField } from "./FormSection"; // Adjust the path if necessary

interface CustomerFormSectionProps {
  formData: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDateChange: (field: string, newValue: Dayjs | null) => void;
  loading: boolean;
}

const customerFields: FormField[] = [
  { label: "Customer First Name", name: "customerFirstName" },
  { label: "Customer Last Name", name: "customerLastName" },
  { label: "Customer Middle Name", name: "customerMiddleName" },
  { label: "Customer Birth Date", name: "customerBirthDate", type: "date" },
  { label: "Customer Email", name: "customerEmail" },
  { label: "Customer Phone", name: "customerPhone" },
  { label: "Customer Address 1", name: "customerAddress1" },
  { label: "Customer Address 2", name: "customerAddress2" },
  { label: "Customer City", name: "customerCity" },
  { label: "Customer State", name: "customerState" },
  { label: "Customer Zip", name: "customerZip" },
];

const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === "Enter") {
    event.preventDefault(); // Prevent default Enter key form submission
  }
};

const CustomerFormSection: React.FC<CustomerFormSectionProps> = ({
  formData,
  handleChange,
  handleDateChange,
  loading,
}) => {

  const getCustomerTitle = (data: any): string => {
    if (!data.customerFirstName || !data.customerLastName) {
      return "Customer";
    }
    const baseName = `Customer: ${data.customerFirstName} ${data.customerLastName}`;
    return data.customerId ? `${baseName} - OYL Customer ID: ${data.customerId}` : baseName;
  };
  
  return (
    <FormSection
      title={getCustomerTitle(formData)}
      fields={customerFields}
      formData={formData}
      handleChange={handleChange}
      handleDateChange={handleDateChange}
      loading={loading}
      onKeyDown={handleKeyDown} // Add this to capture Enter key press
    />
  );
};

export default CustomerFormSection;
